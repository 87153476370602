import {
	BLOCK_SLOT_FOOTER,
	BLOCK_TYPE_NAVIGATION,
	BLOCK_TYPE_ECOMMERCE_PRODUCT,
	BLOCK_TYPE_ECOMMERCE_PRODUCT_LIST,
	PAGE_TYPE_ECOMMERCE_PRODUCT,
	SYSTEM_LOCALE,
} from '@zyro-inc/site-modules/constants';
import { getLanguageSwitcherLanguages } from '@zyro-inc/site-modules/utils/getLanguageSwitcherLanguages';
import { filterLanguageHiddenPages } from '@zyro-inc/site-modules/utils/filterHiddenPages';

const GLOBAL_BLOCK_TYPES = [
	BLOCK_TYPE_NAVIGATION,
	BLOCK_TYPE_ECOMMERCE_PRODUCT,
	BLOCK_TYPE_ECOMMERCE_PRODUCT_LIST,
];

export const getPageBlocks = ({
	siteBlocks,
	pageBlocksIds,
}) => Object.fromEntries(Object.entries(siteBlocks).filter(([
	blockId,
	{
		type,
		slot,
	},
]) => {
	const isPageBlock = pageBlocksIds.includes(blockId);
	const isGlobalBlock = GLOBAL_BLOCK_TYPES.includes(type);
	const isFooterBlock = slot === BLOCK_SLOT_FOOTER;

	return isPageBlock || isGlobalBlock || isFooterBlock;
}));

export const getPageElementsFromPageBlocks = ({
	siteElements,
	blocks,
}) => {
	const pageBlocksElementIds = Object.values(blocks).map(({ components }) => components).flatMap((components) => components);

	return Object.fromEntries(Object.entries(siteElements).filter(([elementId]) => pageBlocksElementIds.includes(elementId)));
};

/**
 * For password protected pages, removes blocks and password value from page data
 */
export const normalizePage = ({
	page,
	isPreviewMode,
}) => {
	if (!page) {
		return null;
	}

	if (!page?.meta?.password || isPreviewMode) {
		return page;
	}

	return {
		...page,
		meta: {
			...page.meta,
			password: true,
		},
		blocks: [],
	};
};

export const getPageData = ({
	siteData,
	locale,
	pageId,
	isAstro,
	isPreviewMode,
}) => {
	const { defaultLocale = SYSTEM_LOCALE } = siteData.meta;

	const productPages = Object.fromEntries(Object.entries(siteData.languages[defaultLocale].pages)
		.filter(([, { type }]) => type === PAGE_TYPE_ECOMMERCE_PRODUCT));

	const productPageIds = Object.entries(productPages)
		.map(([id]) => id);

	const languageData = siteData.languages[locale];

	const {
		blocks: defaultLocaleBlocks,
		elements: defaultLocaleElements,
	} = siteData.languages[defaultLocale];

	const page = normalizePage({
		page: languageData.pages[pageId],
		isPreviewMode,
	});

	if (!page) {
		return null;
	}

	const doesLocalizedFooterExist = Object.values(languageData.blocks).some(({ slot }) => slot === BLOCK_SLOT_FOOTER);
	const defaultBlocks = doesLocalizedFooterExist ? Object.entries(defaultLocaleBlocks).filter((block) => block.type !== 'footer') : defaultLocaleBlocks;

	const pageBlocks = getPageBlocks({
		// destructure order is important because page blocks share same blockIds
		// thus defaultLocaleBlocks need to be overridden to retain current page block data
		siteBlocks: {
			...defaultBlocks,
			...languageData.blocks,
		},
		pageBlocksIds: [
			...page.blocks,
			...productPageIds,
		],
	});

	const pageElements = getPageElementsFromPageBlocks({
		siteElements: {
			...defaultLocaleElements,
			...languageData.elements,
		},
		blocks: pageBlocks,
	});

	// Filter not published pages
	const filteredPages = filterLanguageHiddenPages(languageData.pages, new Date().setHours(0, 0, 0, 0), isAstro);
	const normalizedPages = Object.fromEntries(Object.entries(filteredPages).map(([pageKey, pageData]) => [
		pageKey,
		normalizePage({
			page: pageData,
			isPreviewMode,
		}),
	]));

	return {
		pages: {
			...normalizedPages,
			// productPages are required for BlockEcommerceProductList
			...productPages,
		},
		siteBlocks: languageData.blocks,
		blocks: pageBlocks,
		elements: pageElements,
		nav: languageData.nav,
		currentLocale: locale,
		homePageId: languageData.homePageId,
		isNavHidden: languageData.isNavHidden,
		cookieBannerAcceptText: languageData.cookieBannerAcceptText,
		cookieBannerDisclaimer: languageData.cookieBannerDisclaimer,
		cookieBannerDeclineText: languageData.cookieBannerDeclineText,
		blogReadingTimeText: languageData.blogReadingTimeText,
		metaTitle: languageData.metaTitle,
		meta: siteData.meta,
		forms: siteData.forms,
		styles: siteData.styles,
		domain: siteData.domain ?? siteData.freeDomain,
		siteId: siteData.siteId,
		ecommerceShoppingCart: siteData.ecommerceShoppingCart,
		blogCategories: siteData.blogCategories,
		languageSwitcherLanguages: getLanguageSwitcherLanguages({
			languages: siteData.languages,
			defaultLocale: siteData.meta.defaultLocale,
		}),
		currentPageId: pageId,
		productId: page.productId,
		languageKeys: Object.keys(siteData.languages),
	};
};
